import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Rund um den Finanzplatz Eschborn-Frankfurt",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-30 18:22:52",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21843: {
        teamId: "21843",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      23137: {
        teamId: "23137",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      23398: {
        teamId: "23398",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
      23399: {
        teamId: "23399",
        teamUciCode: "BBH",
        teamName: "Burgos-BH",
        teamNationCode: "ESP",
      },
    },
    riders: {
      6557: {
        id: 6557,
        startno: 77,
        firstName: "Lukasz",
        lastName: "Wisniowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1991-12-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      20945: {
        id: 20945,
        startno: 157,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      70759: {
        id: 70759,
        startno: 96,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      27101: {
        id: 27101,
        startno: 67,
        firstName: "Omer",
        lastName: "Goldstein",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1996-08-13",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      9014: {
        id: 9014,
        startno: 43,
        firstName: "Chun Kai",
        lastName: "Feng",
        nationCode: "TPE",
        nationName: "Taiwan",
        birthDate: "1988-11-02",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 33,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37434: {
        id: 37434,
        startno: 176,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      38289: {
        id: 38289,
        startno: 171,
        firstName: "Pierre",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-09-25",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      2982: {
        id: 2982,
        startno: 61,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      53490: {
        id: 53490,
        startno: 141,
        firstName: "Rune",
        lastName: "Herregodts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-27",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      92666: {
        id: 92666,
        startno: 25,
        firstName: "Biniam",
        lastName: "Girmay",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "2000-04-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      1009: {
        id: 1009,
        startno: 64,
        firstName: "Alex",
        lastName: "Dowsett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-10-03",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      8813: {
        id: 8813,
        startno: 154,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1992-10-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      45363: {
        id: 45363,
        startno: 1,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      37761: {
        id: 37761,
        startno: 112,
        firstName: "Jaakko",
        lastName: "H\u00c3\u00a4nninen",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1997-04-16",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      38803: {
        id: 38803,
        startno: 27,
        firstName: "Georg",
        lastName: "Zimmermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-11",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      63685: {
        id: 63685,
        startno: 153,
        firstName: "Daan",
        lastName: "Hoole",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      1783: {
        id: 1783,
        startno: 132,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      51269: {
        id: 51269,
        startno: 124,
        firstName: "Valentin",
        lastName: "Ferron",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-02-08",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      46161: {
        id: 46161,
        startno: 75,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      27229: {
        id: 27229,
        startno: 87,
        firstName: "Jonas",
        lastName: "Abrahamsen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-09-20",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      56031: {
        id: 56031,
        startno: 55,
        firstName: "Joel",
        lastName: "Suter",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-10-25",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      69918: {
        id: 69918,
        startno: 163,
        firstName: "C\u00c3\u00a9riel",
        lastName: "Desal",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-10-20",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      17179: {
        id: 17179,
        startno: 104,
        firstName: "Kevin",
        lastName: "Ledanois",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-13",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      16823: {
        id: 16823,
        startno: 121,
        firstName: "Niccolo",
        lastName: "Bonifazio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      7580: {
        id: 7580,
        startno: 117,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      50933: {
        id: 50933,
        startno: 16,
        firstName: "Frederik",
        lastName: "Rodenberg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-01-22",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      165: {
        id: 165,
        startno: 174,
        firstName: "Pierre",
        lastName: "Rolland",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-10-10",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      46012: {
        id: 46012,
        startno: 135,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-22",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      49477: {
        id: 49477,
        startno: 147,
        firstName: "Sasha",
        lastName: "Weemaes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-02-09",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      25674: {
        id: 25674,
        startno: 183,
        firstName: "Juan Antonio",
        lastName: "Lopez-Cozar",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-20",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 11,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      7730: {
        id: 7730,
        startno: 36,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 62,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      27304: {
        id: 27304,
        startno: 116,
        firstName: "Damien",
        lastName: "Touz\u00c3\u00a9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      47841: {
        id: 47841,
        startno: 37,
        firstName: "Ben",
        lastName: "Zwiehoff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-22",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 53,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      65580: {
        id: 65580,
        startno: 44,
        firstName: "Filip",
        lastName: "Maciejuk",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1999-09-03",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 151,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      9154: {
        id: 9154,
        startno: 95,
        firstName: "Pierre-Luc",
        lastName: "P\u00c3\u00a9richon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-01-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      3277: {
        id: 3277,
        startno: 31,
        firstName: "Sam",
        lastName: "Bennett",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1990-10-16",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      2172: {
        id: 2172,
        startno: 127,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 4,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      56094: {
        id: 56094,
        startno: 166,
        firstName: "Quentin",
        lastName: "Venner",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-06-15",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      918: {
        id: 918,
        startno: 152,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      97996: {
        id: 97996,
        startno: 47,
        firstName: "Edoardo",
        lastName: "Zambanini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-04-21",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      66276: {
        id: 66276,
        startno: 144,
        firstName: "Gilles",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-10-12",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      27350: {
        id: 27350,
        startno: 86,
        firstName: "Jonas",
        lastName: "Gregaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-07-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 46,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      9313: {
        id: 9313,
        startno: 74,
        firstName: "Hideto",
        lastName: "Nakane",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1990-05-02",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      39368: {
        id: 39368,
        startno: 5,
        firstName: "Lionel",
        lastName: "Taminiaux",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-21",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      10920: {
        id: 10920,
        startno: 187,
        firstName: "Mihkel",
        lastName: "R\u00c3\u00a4im",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1993-07-03",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      77148: {
        id: 77148,
        startno: 17,
        firstName: "Marius",
        lastName: "Mayrhofer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-09-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      7723: {
        id: 7723,
        startno: 94,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      27331: {
        id: 27331,
        startno: 92,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      5811: {
        id: 5811,
        startno: 126,
        firstName: "Lorrenzo",
        lastName: "Manzin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-07-26",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      7731: {
        id: 7731,
        startno: 66,
        firstName: "Rick",
        lastName: "Zabel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      87901: {
        id: 87901,
        startno: 82,
        firstName: "Anders Halland",
        lastName: "Johannessen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-08-23",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      6559: {
        id: 6559,
        startno: 134,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1989-02-03",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      64952: {
        id: 64952,
        startno: 162,
        firstName: "Louis",
        lastName: "Blouwe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-19",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      965: {
        id: 965,
        startno: 103,
        firstName: "Anthony",
        lastName: "Delaplace",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      22389: {
        id: 22389,
        startno: 111,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      18281: {
        id: 18281,
        startno: 175,
        firstName: "Sebastian",
        lastName: "Sch\u00c3\u00b6nberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-05-14",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      6496: {
        id: 6496,
        startno: 102,
        firstName: "Winner",
        lastName: "Anacona",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1988-08-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      169: {
        id: 169,
        startno: 173,
        firstName: "Jonathan",
        lastName: "Hivert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-03-23",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      780: {
        id: 780,
        startno: 93,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      45356: {
        id: 45356,
        startno: 146,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      37500: {
        id: 37500,
        startno: 182,
        firstName: "Angel",
        lastName: "Fuentes",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-11-05",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      16154: {
        id: 16154,
        startno: 105,
        firstName: "Daniel",
        lastName: "McLay",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-01-03",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      31208: {
        id: 31208,
        startno: 12,
        firstName: "Cees",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-07-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      18249: {
        id: 18249,
        startno: 23,
        firstName: "Tom",
        lastName: "Devriendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      65604: {
        id: 65604,
        startno: 114,
        firstName: "Antoine",
        lastName: "Raugel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-14",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      18260: {
        id: 18260,
        startno: 35,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      64761: {
        id: 64761,
        startno: 184,
        firstName: "Alex",
        lastName: "Molenaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-13",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      37304: {
        id: 37304,
        startno: 85,
        firstName: "Syver",
        lastName: "W\u00c3\u00a6rsted",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-08-08",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      70377: {
        id: 70377,
        startno: 137,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      33063: {
        id: 33063,
        startno: 122,
        firstName: "Fabien",
        lastName: "Doubey",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-21",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      50030: {
        id: 50030,
        startno: 26,
        firstName: "Barnabas",
        lastName: "Peak",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-11-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      9008: {
        id: 9008,
        startno: 156,
        firstName: "Simon",
        lastName: "Pellaud",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-11-06",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      624: {
        id: 624,
        startno: 54,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      71213: {
        id: 71213,
        startno: 165,
        firstName: "Laurenz",
        lastName: "Rex",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-12-15",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      39175: {
        id: 39175,
        startno: 107,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-02",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      27268: {
        id: 27268,
        startno: 32,
        firstName: "Matteo",
        lastName: "Fabbro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-10",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      54613: {
        id: 54613,
        startno: 143,
        firstName: "Alex",
        lastName: "Colman",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-22",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      37403: {
        id: 37403,
        startno: 81,
        firstName: "Niklas",
        lastName: "Larsen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-03-22",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      16783: {
        id: 16783,
        startno: 125,
        firstName: "Christopher",
        lastName: "Lawless",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 42,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      3051: {
        id: 3051,
        startno: 6,
        firstName: "Guillaume",
        lastName: "Van Keirsbulck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-02-14",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      46683: {
        id: 46683,
        startno: 186,
        firstName: "\u00c3\u0093scar",
        lastName: "Pelegr\u00c3\u00ad",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-05-30",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      65440: {
        id: 65440,
        startno: 15,
        firstName: "Leon",
        lastName: "Heinschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-11-08",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      64658: {
        id: 64658,
        startno: 71,
        firstName: "Marijn",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-19",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      920: {
        id: 920,
        startno: 73,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      79947: {
        id: 79947,
        startno: 113,
        firstName: "Paul",
        lastName: "Lapeira",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 45,
        firstName: "Jasha",
        lastName: "S\u00c3\u00bctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      4953: {
        id: 4953,
        startno: 24,
        firstName: "Sven Erik",
        lastName: "Bystr\u00c3\u00b8m",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-01-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      37294: {
        id: 37294,
        startno: 97,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      71209: {
        id: 71209,
        startno: 161,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-22",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      32892: {
        id: 32892,
        startno: 101,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      16803: {
        id: 16803,
        startno: 56,
        firstName: "Oliviero",
        lastName: "Troia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-01",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      875: {
        id: 875,
        startno: 63,
        firstName: "Matthias",
        lastName: "Br\u00c3\u00a4ndle",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1989-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      18575: {
        id: 18575,
        startno: 51,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      903: {
        id: 903,
        startno: 172,
        firstName: "Cyril",
        lastName: "Gautier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-09-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      20454: {
        id: 20454,
        startno: 91,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-12",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      45371: {
        id: 45371,
        startno: 145,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      7639: {
        id: 7639,
        startno: 133,
        firstName: "Carlos",
        lastName: "Barbero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 72,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      27086: {
        id: 27086,
        startno: 167,
        firstName: "Tom",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1996-03-04",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      16581: {
        id: 16581,
        startno: 22,
        firstName: "Aim\u00c3\u00a9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      45620: {
        id: 45620,
        startno: 52,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-10-10",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      9349: {
        id: 9349,
        startno: 34,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      1615: {
        id: 1615,
        startno: 181,
        firstName: "Jetse",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-09-08",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      113439: {
        id: 113439,
        startno: 131,
        firstName: "Arnaud",
        lastName: "De Lie",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-03-16",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      38938: {
        id: 38938,
        startno: 123,
        firstName: "Sandy",
        lastName: "Dujardin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-05-29",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      6236: {
        id: 6236,
        startno: 136,
        firstName: "R\u00c3\u00bcdiger",
        lastName: "Selig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-02-19",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      27232: {
        id: 27232,
        startno: 84,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-05-07",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 2,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      64689: {
        id: 64689,
        startno: 164,
        firstName: "Johan",
        lastName: "Meens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-07-07",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      3133: {
        id: 3133,
        startno: 106,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 3,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      94607: {
        id: 94607,
        startno: 142,
        firstName: "Jenno",
        lastName: "Berckmoes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-02-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      48119: {
        id: 48119,
        startno: 83,
        firstName: "Morten",
        lastName: "Hulgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-08-23",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      69145: {
        id: 69145,
        startno: 14,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      20397: {
        id: 20397,
        startno: 155,
        firstName: "Jacopo",
        lastName: "Mosca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-08-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      2317: {
        id: 2317,
        startno: 7,
        firstName: "Julien",
        lastName: "Vermote",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-07-26",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      46682: {
        id: 46682,
        startno: 185,
        firstName: "Felipe",
        lastName: "Orts Lloret",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-04-01",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      16817: {
        id: 16817,
        startno: 13,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      38164: {
        id: 38164,
        startno: 76,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      7579: {
        id: 7579,
        startno: 65,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-04-19",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      17210: {
        id: 17210,
        startno: 115,
        firstName: "Marc",
        lastName: "Sarreau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-10",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      16816: {
        id: 16816,
        startno: 41,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 21,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Eschborn Frankfurt"
  const raceID = 35

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
